/**
 * 公共常量
 */
const constant = {
  // 表单配置
  FORM_OPTION: {
    // input
    INPUT: {
      // 最大字数
      MAX_LENGTH: {
        COMMON: 9999999 // 通用
      },
      PHONE_LENGTH: {
        COMMON: 13
      }
    },
    // textarea
    TEXT_AREA: {
      // 最大字数
      MAX_LENGTH: {
        COMMON: 9999999 // 通用
      }
    }
  },
  CALENDAR_WEEKEDN: {
    WEEKDAY: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
    ROOM_STATUS: ['available', 'maintenance', 'closed', 'to_check_in', 'checked_in'],
    ROOM_STATUS_CLSSNAMES: ['blue', 'black', 'red', 'yellow', 'green']
  },
  // 页面跳转间隔时间
  ROUTER_CHANGE_INTERVAL: 800
}

export default constant
