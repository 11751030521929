<template>
  <alter-form
    labelWidth="150px"
    :watchImageVisible="true"
    :collapseLength="collapseLength"
    :modifyData="modifyData"
    @formSubmit="formSubmit"
    :formData="formData"
  />
</template>

<script>
// 组件
import alterForm from '@/components/alterForm'
import {
  createRestAddon, 
  getRestList,
  getRestAddonInfoPreview,
  updateRestAddonInfo
} from '@/services/rests';

import constant from '@/utils/constant.js'
import jsonData from './jsonData'
import utils from '@/utils/Utils'
import loading from '@/utils/loading.json'
import _ from 'lodash'
export default {
  components: {
    alterForm
  },
  data () {
    return {
      constant,
      formData: _.cloneDeep(jsonData.formData),
      modifyData: {},
      collapseLength: [],
      id: ''
    }
  },
  created () {
    this.id = this.$route.query.id || ''
    this.initData()
  },
  methods: {
    async initData () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      const res = await getRestList()
      const { arr } = utils.formatTableData(res.data.objects)
      this.formData[0].createDateItem[1].options = arr
      if (this.id) {
        const restRes = await getRestAddonInfoPreview(this.id)
        if (restRes.status == 200 && restRes.data) {
          const placeObj = JSON.parse(JSON.stringify(restRes.data.rest_addon))
          placeObj.images_attributes = placeObj.images
          delete placeObj['images']
          this.modifyData = { ...placeObj }
        }
      }
      this.$store.state.app.loading.close()
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      let api = () => createRestAddon(data)
      if (this.id) {
        api = () => updateRestAddonInfo(this.id, data)
      }
      api().then((res) => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'restsManagerAddonList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>