import request from '@/utils/request'
import store from '@/store'
import loading from '@/utils/loading.json'

export default {
  toFormData (formdata, name) {
    const data = new FormData()
    if (Object.keys(formdata).length > 0) {
      Object.keys(formdata).forEach(key => {
        if (formdata[key] instanceof Array) {
          formdata[key].forEach((sonkey, index) => {
            if (formdata[key][index][sonkey] instanceof Array) {
              formdata[key][index][sonkey].forEach((sonsonkey, sonindex) => {
                data.append(sonsonkey, formdata[key][index][sonkey][sonindex][sonsonkey])
              })
            } else if (typeof formdata[key][index] !== 'string' && Object.keys(formdata[key][index]).length > 0) {
              Object.keys(formdata[key][index]).forEach(sonkeytank => {
                if (formdata[key][index][sonkeytank] instanceof Array) {
                  formdata[key][index][sonkeytank].forEach((it, itindex) => {
                    Object.keys(it).forEach((itson, itsonIndex) => {
                      data.append(`${name}[${key}][${index}][${sonkeytank}[${itindex}][${itson}]]`, formdata[key][index][sonkeytank][itindex][itson])
                    })
                  })
                } else if (sonkeytank === 'image_url') {
                  // console.log(formdata[key][index][sonkeytank].name)
                  data.append(`${name}[${key}][${index}][remote_image_url]`, formdata[key][index][sonkeytank], formdata[key][index][sonkeytank].name)
                } else {
                  data.append(`${name}[${key}][${index}][${sonkeytank}]`, formdata[key][index][sonkeytank])
                }
              })
            } else if (formdata[key] instanceof Array) {
              data.append(`${name}[${key}][]`, formdata[key][index])
            } else {
              data.append(`${name}[${key}][${index}]`, formdata[key][index])
            }
          })
        } else {
          data.append(`${name}[${key}]`, formdata[key])
        }
      })
    }
    return data
  },
  dateFormat (fmt, datename) {
    let ret
    const date = new Date(datename)
    const opt = {
      'Y+': date.getFullYear().toString(), // 年
      'm+': (date.getMonth() + 1).toString(), // 月
      'd+': date.getDate().toString(), // 日
      'H+': date.getHours().toString(), // 时
      'M+': date.getMinutes().toString(), // 分
      'S+': date.getSeconds().toString() // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (const k in opt) {
      ret = new RegExp('(' + k + ')').exec(fmt)
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
      }
    }
    return fmt
  },
  getTime (dateStr) {
    var date = new Date(dateStr)
    var Month = date.getMonth() + 1
    var Day = date.getDate()
    var Y = date.getFullYear() + '-'
    var M = Month < 10 ? '0' + Month + '-' : Month + '-'
    var D = Day < 10 ? '0' + Day : Day
    return Y + M + D
  },
  compareTime (startTime, endTime) {
    // var start_time=startTime.replace(/-|\s|:|\//g,'').replace(' ', ''); // 用这个加强版也可以
    var start_time = startTime.replace(/-|\s|:|\//g, '')
    var end_time = endTime.replace(/-|\s|:|\//g, '')
    if (Number(start_time) <= Number(end_time)) {
      return true
    } else {
      return false
    }
  },
  isNull (data) {
    if (data === undefined || data === null || (data instanceof Array && data.length === 0) || data === '') {
      return true
    } else {
      return false
    }
  },
  fileExport (data) {
    const filename = data.headers['content-disposition'].split('=')[1].replaceAll('"', '')
    const blob = new Blob([data.data])
    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob)
    downloadElement.href = href
    downloadElement.download = filename.toString()
    document.body.appendChild(downloadElement)
    downloadElement.click()
    document.body.removeChild(downloadElement)
    window.URL.revokeObjectURL(href)
  },
  custOperate (data, el, other, extra) {
    if (extra) {
      this.getConfirmation(data, el, other, extra)
    } else {
      this.getConfirmation(data, el, other)
    }
  },
  getConfirmation (data, el, other, extra) {
    if (data.confirmation) {
      el.$confirm(data.confirmation, el.$t('text.confirmation'), {
        confirmButtonText: el.$t('button.confirm'),
        cancelButtonText: el.$t('button.cancel'),
        type: 'warning'
      }).then(() => {
        if (data.extra_params && Object.keys(data.extra_params).length > 0) {
          const params = extra
          this.setTableRequest(data, el, other, params)
        } else {
          this.setTableRequest(data, el, other)
        }
      }).catch(() => {
      })
    } else {
      if (data.extra_params && Object.keys(data.extra_params).length > 0) {
        const params = extra
        this.setTableRequest(data, el, other, params)
      } else {
        this.setTableRequest(data, el, other)
      }
    }
  },
  setTableRequest (data, el, other, extra) {
    store.state.app.loading = el.$loading(loading)
    // if (data.path.includes('http')) {
    //   store.url = data.path
    // }
    if (data.action_type && data.action_type === 'new_window') {
      window.location.href = data.path
      store.state.app.loading.close()
      // el.$message({
      //   type: 'success',
      //   message: el.$t('message.downSuccess')
      // })
      return
    }
    const params = {
      method: data.method,
      url: '/cms' + data.path
    }
    // if (extra && Object.keys(extra).length > 0) Object.assign(params, extra)
    let otherData = {}
    if (other) otherData = JSON.parse(JSON.stringify(other))
    if (otherData && extra && Object.keys(extra).length > 0) Object.assign(otherData, extra)
    if (otherData && data.method === 'POST') Object.assign(params, { data: otherData })
    if (otherData && data.method === 'PUT') Object.assign(params, { data: otherData })
    if (otherData && data.method === 'GET') Object.assign(params, { params: otherData })
    if (data.method === 'GET') {
      Object.assign(params, { responseType: 'blob', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
    }
    request(params).then((res) => {
      if (el.forceable && res.data.type === 'force') {
        // el.$message({
        //   type: 'success',
        //   message: el.$t('message.operateSuccess')
        // })
        el.$confirm(res.data.message, el.$t('text.doubleConfirm'), {
          confirmButtonText: el.$t('button.forceBtn'),
          cancelButtonText: el.$t('button.cancel'),
          type: 'warning'
        }).then(() => {
          Object.assign(extra, { force: true })
          this.setTableRequest(data, el, other, extra)
          el.forceable = false
        }).catch(() => {
        })
      } else if (data.redirect_url) {
        el.jmpRouter(data.redirect_url, res.data)
      } else {
        el.$message({
          type: 'success',
          message: el.$t('message.operateSuccess')
        })
      }
      if (data.method !== 'GET') {
        el.search()
      } else {
        this.fileExport(res)
      }
    }).catch(() => {
    }).finally(() => {
      if (el.dialogSearch) el.dialogSearch()
      store.state.app.loading.close()
      // store.url = null
    })
  },
  /**
   * 分转元
   * @param {Sting ｜ Number} num 分
   * @returns {Number} 元
   */
  centToYuan (num) {
    return Number(num) / 100
  },
  /**
   * 元转分
   * @param {Sting ｜ Number} num 元
   * @returns {Number} 分
   */
  yuanToCent (num) {
    return Number(num) * 100
  },
  /**
   * 添加千分符
   * @param {Sting ｜ Number} num
   * @returns {String}
   */
  numberFormat (num) {
    if (!num) return 0
    const numArr = num.toString().split('.')
    const reg = /\d{1,3}(?=(\d{3})+$)/g
    let a = numArr[0].replace(reg, '$&,')
    if (numArr[1]) {
      a = `${a}.${numArr[1]}`
    }
    return a
  },
  /**
   * money添加千分符自动保留两位小数
   * @param {Sting ｜ Number} num 元
   * @returns {String} 分
   */
  moneyFormat (num) {
    if (!num) return '0.00'
    const money = this.centToYuan(num).toFixed(2)
    return this.numberFormat(money)
  },

  formatTableData (data, list) {
    let arr = [];
    data.forEach((d) => {
      let obj = {}
      Object.keys(d).forEach((k) => {
        if (Object.hasOwn(d[k], 'name') && list) {
          let idx = list.findIndex((l) => l.label == d[k].name)
          list[idx].prop = k
        }
        if (Object.hasOwn(d[k], 'value')) {
          obj[k] = d[k].value
        } else {
          obj[k] = d[k]
        }
      })
      arr.push(obj)
    })
    return {
      arr,
      columnList: list || []
    }
  }
}
