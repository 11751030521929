import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'
export default {
  formData: [{
    title: '',
    name: 0,
    createDateItem: [{
      span: 18,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 18,
      prop: 'rest_id',
      name: 'select',
      options: [],
      label: i18n.t('form.rest.restList'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        key: 'id',
        value: 'id',
        filterable: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.rest.restList') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.product.cnName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.cnName') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.product.enName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'rank',
      name: 'input',
      type: 'number',
      label: i18n.t('form.product.ranking'),
      placeholder: i18n.t('placeholder.showRanking'),
      Object: {
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.ranking') }) },
          { pattern: new RegExp(/^[1-9]\d*$/, 'g'), message: i18n.t('message.positiveInter', { name: i18n.t('form.product.ranking') }), trigger: 'blur' }
        ]
      }
    },  {
      span: 24,
      prop: 'images_attributes',
      name: 'image',
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 100
      }
    }, {
      span: 18,
      prop: 'intro',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.product.shortDescriptionCn'),
      Object: {}
    }, {
      span: 18,
      prop: 'intro_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.product.shortDescriptionEn'),
      Object: {}
    }, {
      span: 8,
      prop: 'price',
      name: 'input',
      type: 'number',
      label: i18n.t('form.rest.price'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.rest.price') }) },
          { pattern: new RegExp(/^[1-9]\d*$/, 'g'), message: i18n.t('message.positiveInter', { name: i18n.t('form.rest.price') }), trigger: 'blur' }
        ]
      }
    }]
  }]
}
