/**
 * 酒店相关请求模块
 */
// import qs from 'qs'
import request from '@/utils/request'
export const getRestSetup = (restSetupType) => {
  return request({
    method: 'GET',
    url: `/cms/${restSetupType}/setup`
  })
}

export const getRestList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/rests',
    params
  })
}
export const deleteRests = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/rests/${id}`
  })
}

export const createRest = (rest) => {
  return request({
    method: 'POST',
    url: '/cms/rests',
    data: {
      rest
    }
  })
}

export const getRestInfoPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rests/${id}/preview`
  })
}

export const updateRestInfo = (id, rest) => {
  return request({
    method: 'PUT',
    url: `/cms/rests/${id}`,
    data: {
      rest
    }
  })
}

export const getRestInfo = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rests/${id}`
  })
}

//酒店设施
export const getRestFacilityList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/rest_facilities',
    params
  })
}

export const createRestFacilities = (rest_facility) => {
  return request({
    method: 'POST',
    url: '/cms/rest_facilities',
    data: {
      rest_facility
    }
  })
}

export const deleteRestFacilities = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/rest_facilities/${id}`
  })
}

export const getRestFacilitiesInfoPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_facilities/${id}/preview`
  })
}

export const updateRestFacilitiesInfo = (rest_facility, id) => {
  return request({
    method: 'PUT',
    url: `/cms/rest_facilities/${id}`,
    data: {
      rest_facility
    }
  })
}

export const getRestFacilitiesInfo = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_facilities/${id}`
  })
}

//酒店标签
export const getRestLabelList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/rest_tags',
    params
  })
}

export const createRestLabel = (rest_tag) => {
  return request({
    method: 'POST',
    url: '/cms/rest_tags',
    data: {
      rest_tag
    }
  })
}

export const deleteRestLabel = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/rest_tags/${id}`
  })
}

export const getRestLabelInfoPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_tags/${id}/preview`
  })
}

export const updateRestLabelInfo = (rest_tag, id) => {
  return request({
    method: 'PUT',
    url: `/cms/rest_tags/${id}`,
    data: {
      rest_tag
    }
  })
}

export const getRestLabelInfo = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_tags/${id}`
  })
}
//酒店房型
export const getRestTypeList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/rest_types',
    params
  })
}

export const deleteRestType = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/rest_types/${id}`
  })
}

export const createRestType = (rest_type) => {
  return request({
    method: 'POST',
    url: '/cms/rest_types',
    data: {
      rest_type
    }
  })
}

export const previewRestType = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_types/${id}/preview`
  })
}

export const updateRestType = (id, rest_type) => {
  return request({
    method: 'PUT',
    url: `/cms/rest_types/${id}`,
    data: {
      rest_type
    }
  })
}
export const getRestTypeInfo = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_types/${id}`
  })
}
//
export const getRestAddonList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/rest_addons',
    params
  })
}
export const deleteAddons = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/rest_addons/${id}`
  })
}

export const createRestAddon = (rest_addon) => {
  return request({
    method: 'POST',
    url: '/cms/rest_addons',
    data: {
      rest_addon
    }
  })
}

export const getRestAddonInfoPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_addons/${id}/preview`
  })
}

export const updateRestAddonInfo = (id, rest_addon) => {
  return request({
    method: 'PUT',
    url: `/cms/rest_addons/${id}`,
    data: {
      rest_addon
    }
  })
}

export const getRestAddonInfo = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_addons/${id}`
  })
}


//酒店房间管理
export const getRestRoomList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/rest_rooms',
    params
  })
}
export const deleteRestRoom = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/rest_rooms/${id}`
  })
}

export const createRestRoom = (rest_room) => {
  return request({
    method: 'POST',
    url: '/cms/rest_rooms',
    data: {
      rest_room
    }
  })
}

export const getRestRoomInfoPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_rooms/${id}/preview`
  })
}

export const updateRestRoomInfo = (id, rest_room) => {
  return request({
    method: 'PUT',
    url: `/cms/rest_rooms/${id}`,
    data: {
      rest_room
    }
  })
}

export const getRestRoomInfo = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_rooms/${id}`
  })
}

//酒店房价房态管理
export const getRestCalendarList = (params) => {
  return  request({
    method: 'GET',
    url: `/cms/rest_calendars`,
    params
  })
}

export const updateRoomsPrice = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/rest_types/${id}/update_rooms_price`,
    data: {
      ...data
    }
  })
}

export const updateRestsCalendar = (rest_calendar) => {
  return request({
    method: 'POST',
    url: '/cms/rest_calendars',
    data: {
      rest_calendar
    }
  })
}